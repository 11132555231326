<!--
 * @Author: xr
 * @Date: 2022-02-17 15:23:10
 * @LastEditors: xr
 * @LastEditTime: 2022-03-03 15:14:34
 * @FilePath: \postStation-office\src\views\subordinate\regular.vue
-->
<template>
  <div>
    <div class="main">
      <div class="main_box">
        <a-row>
          <a-col :span="7">
            <div class="right-side-assembly">
              <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent"/>
              <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
            </div>
          </a-col>
          <a-col  :span="17" class="news-information-left">
            <div style="min-height:55vh">
             <journalism :newsData="content" @details="handleClick"/>
            </div>
          </a-col>

        </a-row>
        <a-row type="flex" justify="center">
          <a-pagination v-show="content.length" v-model="pageNo" :total="total" :default-page-size="pageSize" @change="onChange" style="text-align: center;padding-left: 270px;margin-top: 20px;margin-bottom: 20px"/>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import FindDemand from '@comp/findDemand'
import Journalism from '@comp/Journalism'
import { getRegular, getRegularById } from '@/api/regular'
import { getDict } from '@/api/index'
import { Page } from '@/mixins/page'
import { Station } from '@/mixins/station'

export default {
  name: 'Regular',
  mixins: [Page, Station],
  components: {
    FindDemand,
    Journalism
  },
  data() {
    return {
      content: [],
      meet: [],
      technicalProjectContent: []
    }
  },
  methods: {
    /**
     * @description:获取政策法规列表
     */
    getList() {
      const params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        stationId: this.stationId
      }
      // 获取政策法规
      getRegular(params).then(res => {
        this.content = res.result.records
        this.total = res.result.total * 1
      })
    },
    /**
     * @description:政策点击事件
     * @param {*} id
     */
    handleClick(id) {
      const params = {
        id: id,
        stationId: this.stationId
      }
      getRegularById(params).then(res => {
        if (this.stationId !== '') {
          this.$router.push({ name: 'newsDetail', query: { stationId: this.stationId, id: id }, params: res.result })
        } else {
          this.$router.push({ name: 'newsDetail', query: { id: id }, params: res.result })
        }
      })
    }
  },
  activated() {
    // 判断子站
    this.getStationId()
    this.getList()
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
  }
}
</script>
<style scoped>
@import "~@assets/css/newsInformation.css";
</style>
