/*
 * @Author: xr
 * @Date: 2022-02-25 16:16:52
 * @LastEditors: xr
 * @LastEditTime: 2022-02-25 16:19:37
 * @FilePath: \postStation-office\src\api\regular.js
 */

import request from '../utils/request'

// 分页查招商政策
export const investmentPolicy = query => {
  return request({
    url: '/sys/website/investmentPolicyController/queryPageList',
    method: 'get',
    params: query
  })
}

// 分页查招商政策ByID
export const investmentPolicyId = query => {
  return request({
    url: '/sys/website/investmentPolicyController/getInvestmentPolicyById',
    method: 'get',
    params: query
  })
}

// 分页政策法规
export const getRegular = query => {
  return request({
    url: '/sys/website/PoliciesAndRegulationsWebsiteController/queryPageList',
    method: 'get',
    params: query
  })
}

// 获取政策法规ByID
export const getRegularById = query => {
  return request({
    url: '/sys/website/PoliciesAndRegulationsWebsiteController/queryById',
    method: 'get',
    params: query
  })
}
